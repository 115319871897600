import { Helmet } from "react-helmet"

type Props = {
  title?: string
  url?: string
  image?: string
  description?: string
}

const SEO = (meta: Props) => {
  return (
    <Helmet
      htmlAttributes={{ lang: "ja-jp" }}
      title={meta.title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: meta.description,
        },
        {
          property: `og:url`,
          content: meta.url,
        },
        {
          property: `og:title`,
          content: meta.title,
        },
        {
          property: `og:description`,
          content: meta.description,
        },
        {
          property: `og:image`,
          content: meta.image,
        },
        {
          property: `og:type`,
          content: meta.description,
        },
        {
          property: `og:site_name`,
          content: `uuuno`,
        },
        {
          property: `og:locale`,
          content: `ja_JP`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:site`,
          content: `@yuta99875070`,
        },
        {
          name: `twitter:title`,
          content: meta.title,
        },
        {
          name: `twitter:description`,
          content: meta.description,
        },
        {
          name: `twitter:image`,
          content: meta.image,
        }
      ]}
    />
  )
}
export default SEO