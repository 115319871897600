import { useState } from 'react'
import { Link } from "gatsby"
import LogoImg from '../../static/logo.svg'
import ListIcon from '../../static/list.svg'

type Props = {
  pageType: string
}

const Header = ({pageType}: Props) => {
  const [isShow, setIsShow] = useState<boolean>(false)
  return (
    <header className="fixed left-0 right-0 border-b sm:static bg-white py-2 sm:py-10">
      <Link to="/">
        <img src={LogoImg} className="w-32 sm:w-40 m-auto" alt="logo" />
      </Link>
      {/* {pageType === 'article' &&
        <div onClick={() => {setIsShow(!isShow)}} className={`${isShow ? 'bg-gray-200' : ''} absolute right-4 top-1.5 p-2 rounded-full sm:hidden`}>
          <img src={ListIcon} className="opacity-60" />
        </div>
      } */}
    </header>
  )
}

export default Header