import Header from "./header"
import Footer from "./footer"

type Props = {
  pageType: string
  children?: React.ReactNode
}

export default function Layout(props: Props) {
  return (
    <div className="text-gray-800 font-sans">
       <Header pageType={props.pageType} />
       {props.children}
       <Footer />
     </div>
  )
}